import { Helper } from './Helper';
export class Linking {
    public static prepareNextWidgetData(widgetRef, slice, tableKeys) {
        const filterInput =
            widgetRef.filterService.filterCacheService.getFiltersInfo(
                widgetRef.userDataCache.emailId,
                widgetRef.configCache.viewId,
                widgetRef.filterStoreKey
            );
        let drilldownFilterObject;
        let widgetId;
        const drillDownFiltersData = {};
        const breadCrumbs = widgetRef.widgetLinkingData.breadCrumbs.slice();
        const breadCrumbsData =
            widgetRef.widgetLinkingData.breadCrumbsData.slice();
        if (widgetRef.widgetData.apiResponse) {
            widgetRef.widgetData.apiResponse = null;
        }
        if (
            widgetRef.compareViewService.linkingArray.get(
                widgetRef.widgetLinkingData.viewId
            )[
                widgetRef.compareViewService.currentLevel[
                    widgetRef.widgetLinkingData.viewId
                ] - 1
            ]
        ) {
            widgetRef.compareViewService.linkingArray.get(
                widgetRef.widgetLinkingData.viewId
            )[
                widgetRef.compareViewService.currentLevel[
                    widgetRef.widgetLinkingData.viewId
                ] - 1
            ]['filters'] = Helper.cloneDeep(filterInput);
        }
        if (widgetRef.widgetData.linking['drillDownType'] === 'column') {
            if (widgetRef.widgetData.linking['legendColumn']['widgetId']) {
                drilldownFilterObject =
                    widgetRef.widgetData.linking['legendColumn']['filters'];
                widgetId =
                    widgetRef.widgetData.linking['legendColumn']['widgetId'];
            } else {
                return;
            }
        } else if (widgetRef.widgetData.linking['drillDownType'] === 'row') {
            if (widgetRef.widgetData.linking['legendRow'][slice]) {
                if (
                    widgetRef.widgetData.linking['legendRow'][slice]['widgetId']
                ) {
                    drilldownFilterObject = widgetRef.widgetData.linking[
                        'legendRow'
                    ][slice]['filters']
                        ? widgetRef.widgetData.linking['legendRow'][slice][
                              'filters'
                          ]
                        : {};
                    widgetId =
                        widgetRef.widgetData.linking['legendRow'][slice][
                            'widgetId'
                        ];
                } else {
                    return;
                }
            } else {
                return;
            }
        } else {
            return;
        }

        const tempInputData = {};
        if (
            drilldownFilterObject &&
            Object.keys(drilldownFilterObject).length
        ) {
            Object.keys(drilldownFilterObject).forEach((key, index) => {
                const object = breadCrumbsData.find((row) => key in row);
                if (object) {
                    tempInputData[drilldownFilterObject[key]] = [object[key]];
                } else {
                    if (key?.toLowerCase() === tableKeys?.[0]?.toLowerCase()) {
                        tempInputData[drilldownFilterObject[key]] = [slice];
                        const tempObject = {};
                        tempObject[key] = slice;
                        breadCrumbsData.push(tempObject);
                        breadCrumbs.push(slice);
                    } else {
                        tempInputData[drilldownFilterObject[key]] = [''];
                    }
                }
                drillDownFiltersData[drilldownFilterObject[key]] = [
                    tempInputData[drilldownFilterObject[key]][0]
                ];
            });
        } else {
            const tempObject = {};
            const slicedKey = tableKeys.length ? tableKeys[0] : '';
            tempObject[slicedKey] = slice;
            breadCrumbsData.push(tempObject);
            breadCrumbs.push(slice);
        }

        return {
            widgetId: widgetId,
            input: tempInputData,
            breadCrumbs: breadCrumbs,
            breadCrumbsData: breadCrumbsData,
            filtersData: drillDownFiltersData
        };
    }

    public static prepareNextWidgetDataUtilization(
        widgetRef,
        slice,
        tableKeys
    ) {
        const filterInput =
            widgetRef.filterService.filterCacheService.getFiltersInfo(
                widgetRef.userDataCache.emailId,
                widgetRef.configCache.viewId,
                widgetRef.filterStoreKey
            );
        let drilldownFilterObject;
        let widgetId;
        const drillDownFiltersData = {};
        if (widgetRef.widgetData.apiResponse) {
            widgetRef.widgetData.apiResponse = null;
        }
        if (
            widgetRef.compareViewService.linkingArray.get(
                widgetRef.widgetLinkingData.viewId
            )[
                widgetRef.compareViewService.currentLevel[
                    widgetRef.widgetLinkingData.viewId
                ] - 1
            ]
        ) {
            widgetRef.compareViewService.linkingArray.get(
                widgetRef.widgetLinkingData.viewId
            )[
                widgetRef.compareViewService.currentLevel[
                    widgetRef.widgetLinkingData.viewId
                ] - 1
            ]['filters'] = Helper.cloneDeep(filterInput);
        }
        if (widgetRef.widgetData.linking['drillDownType'] === 'column') {
            if (widgetRef.widgetData.linking['legendColumn']['widgetId']) {
                drilldownFilterObject =
                    widgetRef.widgetData.linking['legendColumn']['filters'];
                widgetId =
                    widgetRef.widgetData.linking['legendColumn']['widgetId'];
            } else {
                return;
            }
        } else if (widgetRef.widgetData.linking['drillDownType'] === 'row') {
            if (widgetRef.widgetData.linking['legendRow'][slice]) {
                if (
                    widgetRef.widgetData.linking['legendRow'][slice]['widgetId']
                ) {
                    drilldownFilterObject = widgetRef.widgetData.linking[
                        'legendRow'
                    ][slice]['filters']
                        ? widgetRef.widgetData.linking['legendRow'][slice][
                              'filters'
                          ]
                        : {};
                    widgetId =
                        widgetRef.widgetData.linking['legendRow'][slice][
                            'widgetId'
                        ];
                } else {
                    return;
                }
            } else {
                return;
            }
        } else {
            return;
        }

        const tempInputData = {};
        if (
            drilldownFilterObject &&
            Object.keys(drilldownFilterObject).length
        ) {
            Object.keys(drilldownFilterObject).forEach((key) => {
                tempInputData[drilldownFilterObject[key]] = slice[key];
                drillDownFiltersData[drilldownFilterObject[key]] = [
                    tempInputData[drilldownFilterObject[key]]
                ];
            });
        } else {
            const tempObject = {};
            const slicedKey = tableKeys.length ? tableKeys : '';
            tempObject[slicedKey] = slice;
        }

        return {
            widgetId,
            input: tempInputData,
            filtersData: drillDownFiltersData
        };
    }
}
