import {
    Component,
    NgZone,
    OnInit,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import {
    GridOptions,
    RowEvent,
    ValueFormatterParams,
    ValueGetterParams,
} from 'ag-grid-community';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { IconName } from 'src/app/shared/classes/Icon';
import { Messages } from 'src/app/shared/classes/Messages';
import { Widget } from 'src/app/shared/classes/Widget';
import { WidgetInjectedData } from 'src/app/shared/classes/WidgetInjectedData';
import { ActionState } from 'src/app/shared/enums/ActionState';
import { ActionVisibility } from 'src/app/shared/enums/ActionVisibility';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { IconType } from 'src/app/shared/enums/IconType';
import { ModalType } from 'src/app/shared/enums/ModalType';
import { IAction } from 'src/app/shared/interfaces/actions/IAction';
import { IConfirmationModal } from 'src/app/shared/interfaces/modal/IConfirmationModal';
import { IModalData } from 'src/app/shared/interfaces/modal/IModalData';
import { ITableGeneratorInput } from 'src/app/shared/interfaces/table-generator/ITableGeneratorInput';
import { HttpService } from 'src/app/shared/services/http/http-main/http.service';
import { NotificationsService } from 'src/app/shared/services/notifications/notifications.service';
import { EaOnboardingModalComponent } from '../../../modal-templates/account-onboarding-modals/ea-onboarding-modal/ea-onboarding-modal.component';
import { BulkUploadAzureEaComponent } from '../../../modal-templates/azure-ea-onboarding-modal/bulk-upload-azure-ea/bulk-upload-azure-ea.component';
import {
    IButtonGeneratorInput,
    IMultiButtonOption,
} from './../../../../interfaces/button-generator/IButtonGeneratorInput';
import { IHitApi } from './../../../../interfaces/hit-api/IHitApi';
import { MultiButtonGeneratorComponent } from './../../../multi-button-generator/multi-button-generator.component';

@Component({
    selector: 'app-ea-listing',
    templateUrl: './ea-listing.component.html',
    styleUrls: ['./ea-listing.component.sass'],
})
export class EaListingComponent implements OnInit {
    tableGenInput: ITableGeneratorInput = null;
    widgetRef: Widget;
    gridRef: GridOptions;
    selectedRowData: Array<string> = [];
    @ViewChild('rowDataSelected') rowDataSelected: TemplateRef<any>;
    @ViewChild('templateData') templateData: TemplateRef<any>;
    mergeWarningMessage: string;
    masterAccountName: string;
    allActions: IAction[] = [];
    iconName = IconName;
    constructor(
        widgetData: WidgetInjectedData,
        private httpService: HttpService,
        private ngZone: NgZone,
        private notificationService: NotificationsService
    ) {
        this.widgetRef = widgetData.widgetRef;
    }

    ngOnInit(): void {
        this.applyTagOperation();
        this.setUpBasics();
    }

    onSelectionChanged() {
        this.gridRef.api.getSelectedNodes().length
            ? this.widgetRef.showNotificationBadge.next(true)
            : this.widgetRef.showNotificationBadge.next(false);
        const resetBulkActions = (checkAccount) => {
            this.widgetRef.operationalActions.value.set(
                'Bulk Action',
                this.allActions
            );
            this.widgetRef.operationalActions.next(
                this.widgetRef.operationalActions.value
            );
        };

        if (this.gridRef.api.getSelectedNodes().length) {
            let checkAccount: boolean = true;
            this.gridRef.api.getSelectedNodes().every((rowData) => {
                if (
                    !(
                        rowData.data['type'] === 'CONSOLIDATED' ||
                        rowData.data['type'] === 'INDEPENDENT'
                    )
                ) {
                    checkAccount = false;
                    return false;
                }
                return true;
            });
            resetBulkActions(checkAccount);
        } else {
            resetBulkActions(false);
        }
    }
    openBulkUploadUpdateModalEA(bulkUpdate?: boolean) {
        let conditionsChecked: boolean = true;
        const rowsData = [];

        if (bulkUpdate) {
            if (this.gridRef.api.getSelectedNodes().length) {
                const accountType =
                    this.gridRef.api.getSelectedNodes()[0].data.type;
                this.gridRef.api
                    .getSelectedNodes()
                    .forEach((rowData, index) => {
                        if (index && rowData.data.type !== accountType) {
                            Helper.showErrorMessage(
                                this.widgetRef.notificationsService,
                                null,
                                'Please select only one type of account to perform this action'
                            );
                            conditionsChecked = false;
                            return;
                        }
                        rowsData.push(rowData.data);
                        if (rowsData.length) {
                            delete rowsData[index]['moreInfo'];
                        }
                    });
            } else {
                Helper.showErrorMessage(
                    this.widgetRef.notificationsService,
                    null,
                    'Select at least One entry to perform the Action'
                );
                return;
            }
        }

        if (!conditionsChecked) {
            return;
        }

        const modalArgs: IModalData = {
            modalName: `Bulk ${
                bulkUpdate ? 'Update' : 'Upload'
            } Azure EA Account`,
            sourceId: this.widgetRef.uniqueIdentity,
            modalType: ModalType.MIDDLE,
            modalAutoHeight: true,
            modalIcon: {
                type: IconType.FONTAWSOME,
                class: '',
            },
            modalWidthVw: 80,
            modalSteps: [
                {
                    stepName: 'Step 1',
                    resetModal: true,
                    stepData: {
                        componentToLoad: BulkUploadAzureEaComponent,
                        payload: {
                            data: {
                                widgetRef: this.widgetRef,
                                bulkUpdate: bulkUpdate,
                                accountType:
                                    bulkUpdate && this.gridRef
                                        ? this.gridRef.api.getSelectedNodes()[0]
                                              .data.type
                                        : null,
                                rowsData,
                            },
                        },
                    },
                },
            ],
        };
        this.widgetRef.modalService.openModal(modalArgs);
    }

    applyTagOperation() {
        const actions: IAction[] = [];
        actions.push({
            state: ActionState.ENABLED,
            visibility: ActionVisibility.VISIBLE,
            message: '',
            function: () => {
                this.mergeWarningMessage = '';
                // this.performActions('delete', 'delete', true);
            },
            icon: {
                type: IconType.SVG,
                class: 'trash',
                text: 'Bulk Delete',
                extraClass: 'inline-fix-box-1',
            },
        });
        actions.push({
            state: ActionState.ENABLED,
            visibility: ActionVisibility.VISIBLE,
            message: '',
            function: () => {
                this.openBulkUploadUpdateModalEA();
            },
            icon: {
                type: IconType.SVG,
                class: 'upload',
                text: 'Bulk Upload',
                extraClass: 'inline-fix-box-1 svg-black-fill'
            }
        });
        actions.push({
            state: ActionState.ENABLED,
            visibility: ActionVisibility.VISIBLE,
            message: '',
            function: () => {
                this.mergeWarningMessage = '';
                this.openBulkUploadUpdateModalEA(true);
            },
            icon: {
                type: IconType.SVG,
                class: 'upload',
                text: 'Bulk Update',
                extraClass: 'inline-fix-box-1 svg-black-fill'
            }
        });
        actions.push({
            state: ActionState.ENABLED,
            visibility: ActionVisibility.VISIBLE,
            message: '',
            function: () => {
                this.mergeWarningMessage = '';
                this.performActions('resume', 'suspendUnsuspend', true);
            },
            icon: {
                type: IconType.SVG,
                class: 'resume',
                text: 'Bulk Resume',
                extraClass: 'inline-fix-box-1 svg-black-fill'
            }
        });
        actions.push({
            state: ActionState.ENABLED,
            visibility: ActionVisibility.VISIBLE,
            message: '',
            function: () => {
                this.mergeWarningMessage = '';
                this.performActions('suspend', 'suspendUnsuspend', true);
            },
            icon: {
                type: IconType.SVG,
                class: 'suspend',
                text: 'Bulk Suspend',
                extraClass: 'inline-fix-box-1 svg-black-fill'
            }
        });
        this.allActions = actions;
        this.widgetRef.operationalActions.value.set('Bulk Action', actions);
        this.widgetRef.operationalActions.next(
            this.widgetRef.operationalActions.value
        );
    }

    setUpBasics() {
        this.tableGenInput = {
            buttons: [
                {
                    buttonName: 'Add Independent Account',
                    buttonType: ButtonType.FLAT,
                    buttonColorType: ButtonColorType.PRIMARY,
                    function: (buttonRef: IButtonGeneratorInput) => {
                        this.openOnboardingModal();
                    }
                },
                {
                    buttonName: 'Update Linked Account',
                    buttonType: ButtonType.FLAT,
                    buttonColorType: ButtonColorType.PRIMARY,
                    showLoader: true,
                    function: (buttonRef: IButtonGeneratorInput) => {
                        buttonRef.loader = true;
                        const args: IHitApi = Helper.generateHitApiConfig(
                            this.widgetRef.widgetData.widgetInfo[
                                'updateLinkedAccounts'
                            ]
                        );
                        args.input = {};
                        args.function = (response) => {
                            this.widgetRef.refreshWidget();
                            buttonRef.loader = false;
                            this.notificationService.showSnackBar(
                                Messages.ACCOUNT_UPDATE_LIST
                            );
                        };
                        args.errorFunction = (error) => {
                            buttonRef.loader = false;
                            this.widgetRef.changeDetectorRef.detectChanges();
                            this.notificationService.showSnackBar(
                                error.error.message,
                                true
                            );
                        };
                        new HitApi(
                            args,
                            this.httpService,
                            this.ngZone
                        ).hitApi();
                    }
                }
            ],
            listExtraction: {
                type: 'DIRECT'
            },
            noDataMessage: 'No Accounts Available',
            widgetIconData: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-user-plus'
            },
            columns: [
                {
                    columnKey: 'accountNumber',
                    columnName: 'Enrollment / Subscription ID'
                },
                {
                    columnKey: 'azureEaAccountsInfo',
                    columnName: 'Application ID',
                    comparator: (a, b) => {
                        const appIdA = a.appId;
                        const appIdB = b.appId;
                        if (appIdA === appIdB) {
                            return 0;
                        } else if (appIdA > appIdB || !appIdB) {
                            return 1;
                        } else {
                            return -1;
                        }
                    },
                    cellRenderer: (rowData: RowEvent) => {
                        return rowData.data.azureEaAccountsInfo.appId;
                    }
                },
                {
                    columnKey: 'accountName',
                    columnName: 'Account Name'
                },
                {
                    columnKey: 'status',
                    columnName: 'Status',
                    columnValueGetter: (params: ValueGetterParams) => {
                        return params.data.status;
                    }
                },
                {
                    columnKey: 'azureEaAccountsInfo',
                    columnName: 'Enrollment Number',
                    comparator: (a, b) => {
                        const enrollmentNumberA = Number(a.enrollmentNumber);
                        const enrollmentNumberB = Number(b.enrollmentNumber);
                        if (
                            isNaN(enrollmentNumberA) ||
                            isNaN(enrollmentNumberB) ||
                            enrollmentNumberA === enrollmentNumberB
                        )
                            return 0;
                        if (enrollmentNumberB > enrollmentNumberA) {
                            return 1;
                        }
                        return -1;
                    },
                    columnValueGetter: (params: ValueGetterParams) => {
                        return params.data?.azureEaAccountsInfo?.enrollmentNumber;
                    },
                },
                {
                    columnKey: 'type',
                    columnName: 'Type'
                },
                {
                    columnKey: 'currency',
                    columnName: 'Currency'
                },
                {
                    columnKey: 'action',
                    columnName: 'Action',
                    pinned: 'right',
                    buttonGen: true,
                    componentFramework: MultiButtonGeneratorComponent,
                    valueFormatter: (rowData: ValueFormatterParams) => {
                        let accountStatus;
                        if (rowData.data['status']) {
                            if (rowData.data['status'] === 'Active') {
                                accountStatus = 'SUSPEND';
                            } else if (
                                rowData.data['status'] === 'Inactive'
                            ) {
                                accountStatus = 'UNSUSPEND';
                            }
                        }

                        const buttons: IButtonGeneratorInput[] = [
                            //EDIT BUTTON
                            {
                                buttonName: 'Edit',
                                buttonType: ButtonType.TEXT,
                                buttonColorType: ButtonColorType.INFO,
                                showLoader: false,
                                function: () => {
                                    this.openOnboardingModal(rowData.data);
                                }
                            },
                            // SUSPEND BUTTON
                            {
                                buttonName:
                                    rowData.data['status'] === 'Inactive'
                                        ? 'Undo'
                                        : 'Suspend',
                                buttonType: ButtonType.TEXT,
                                buttonColorType:
                                    rowData.data['status'] === 'Inactive'
                                        ? ButtonColorType.INFO
                                        : ButtonColorType.WARN,
                                showLoader: true,
                                function: (
                                    buttonRef: IButtonGeneratorInput
                                ) => {
                                    const modalData: IConfirmationModal = {
                                        modalName: rowData.data['status']
                                            ? rowData.data['status'] ===
                                              'Inactive'
                                                ? 'Recover EA Account'
                                                : 'Suspend EA Account'
                                            : 'Suspend EA Account',
                                        modalIcon: {
                                            type: IconType.FONTAWSOME,
                                            class: rowData.data['status']
                                                ? rowData.data[
                                                      'status'
                                                  ] === 'Inactive'
                                                    ? 'fas fa-undo'
                                                    : 'fas fa-trash'
                                                : 'fas fa-trash'
                                        },
                                        contextIcon: {
                                            extraClass: 'color-accent',
                                            type: IconType.FONTAWSOME,
                                            class: 'fas fa-exclamation-triangle'
                                        },
                                        confirmationMessage: `Are you sure you want to ${accountStatus.toLowerCase()} ${
                                            rowData.data.accountName
                                        }`,
                                        buttonText: rowData.data[
                                            'status'
                                        ]
                                            ? rowData.data['status'] ===
                                              'Inactive'
                                                ? 'Recover'
                                                : 'Suspend'
                                            : 'Suspend',
                                        buttonColorType:
                                            ButtonColorType.SECONDARY,
                                        loaderOnExec: true,
                                        function: (modalId: Symbol) => {
                                            const accountNumber = rowData.data['accountNumber'];
                                            const apiArgs =
                                                Helper.generateHitApiConfig(
                                                    this.widgetRef.widgetData
                                                        .widgetInfo[
                                                        'suspendUnsuspend'
                                                    ]
                                                );
                                            apiArgs.input = [accountNumber];
                                            apiArgs.intactUrl = apiArgs.url;
                                            apiArgs.url =
                                                apiArgs.url.replace(
                                                    '{id}',
                                                    rowData.data.accountNumber
                                                ) + `?status=${accountStatus}`;
                                            apiArgs.function = () => {
                                                this.widgetRef.notificationsService.showSnackBar(
                                                    `${rowData.data.accountName.toUpperCase()} ${accountStatus}ED SUCCESSFULLY`
                                                );
                                                this.widgetRef.refreshWidget();
                                                this.widgetRef.modalService.closeModal(
                                                    null,
                                                    modalId
                                                );
                                            };
                                            apiArgs.errorFunction = (error) => {
                                                this.widgetRef.modalService.closeModal(
                                                    null,
                                                    modalId
                                                );
                                                Helper.showErrorMessage(
                                                    this.notificationService,
                                                    error,
                                                    'Error perfoming action'
                                                );
                                            };

                                            new HitApi(
                                                apiArgs,
                                                this.widgetRef.httpService,
                                                this.widgetRef.ngZone
                                            ).hitApi();
                                        }
                                    };
                                    this.widgetRef.modalService.openConfirmationModal(
                                        modalData
                                    );
                                }
                            }
                        ];

                        const buttonOption: IMultiButtonOption = {
                            layout: {
                                justifyContent: 'space-evenly'
                            }
                        };
                        rowData['options'] = buttonOption;
                        rowData['buttonGenInputs'] = buttons;
                        return rowData;
                    }
                }
            ],
            afterResponse: null,
            selection: 'multiple'
        };
    }

    createPayload(payLoadForMerge?: boolean) {
        const payloadData = [];

        this.gridRef.api.getSelectedNodes().forEach((rowData) => {
            if (payLoadForMerge) {
                payloadData.push({
                    accountName: rowData.data[EaAttributeKeys.ACCOUNT_NAME],
                    partnerId:
                        rowData.data[EaAttributeKeys.EA_ACCOUNT_ATTRIBUTE][
                            EaAttributeKeys.PARTNER_ID
                        ],
                    azurePlanAccountsAttributes: {
                        subscriptionID:
                            rowData.data[EaAttributeKeys.ACCOUNT_NUMBER],
                    },
                    type: 'CONSOLIDATED',
                });
            } else {
                payloadData.push(rowData.data[EaAttributeKeys.ACCOUNT_NUMBER]);
            }
        });

        return payloadData;
    }

    performActions(
        actionPerform: string,
        apiName: string,
        bulkAction: boolean,
        selectedId?: string
    ) {
        if (bulkAction) {
            this.selectedRowData = [];
            if (this.gridRef && !this.gridRef.api.getSelectedNodes().length) {
                Helper.showErrorMessage(
                    this.widgetRef.notificationsService,
                    null,
                    'Select atleast One entry to perform the Action'
                );
                return;
            } else {
                this.gridRef.api.getSelectedNodes().forEach((rowValue) => {
                    this.selectedRowData.push(
                        rowValue.data[EaAttributeKeys.ACCOUNT_NAME]
                    );
                });
            }
        }

        const modalData: IConfirmationModal = {
            modalName: `${
                actionPerform.includes('s')
                    ? actionPerform[0] === 's'
                        ? 'Suspend'
                        : 'Resume'
                    : 'Delete'
            } Azure EA Account`,
            confirmationMessage: `Are you sure you want to ${actionPerform} the following Account ?`,
            buttonText: 'Confirm',
            loaderOnExec: true,
            extraClass: 'plan-container form-control-margin-bottom-container',
            contextIcon: {
                type: IconType.FONTAWSOME,
                class: '',
            },
            fontSize: 1.12,
            bodyTemplate: this.rowDataSelected,
            buttonColorType: actionPerform.includes('delete')
                ? ButtonColorType.WARN
                : ButtonColorType.PRIMARY,
            modalAutoHeight: false,
            modalHeightVh: 50,
            function: (modalId: Symbol) => {
                const apiArgs: IHitApi = Helper.generateHitApiConfig(
                    this.widgetRef.widgetData.widgetInfo[`${apiName}`]
                );
                apiArgs.input = bulkAction
                    ? this.createPayload()
                    : [selectedId];
                if (actionPerform.includes('s')) {
                    apiArgs.url =
                        apiArgs.url +
                        `?status=${
                            actionPerform[0] === 'r' ? 'UNSUSPEND' : 'SUSPEND'
                        }`;
                }

                apiArgs.function = () => {
                    this.widgetRef.notificationsService.showSnackBar(
                        `Account ${actionPerform} successfully`
                    );
                    this.widgetRef.refreshWidget();
                    this.widgetRef.modalService.closeModal(null, modalId);
                };
                apiArgs.errorFunction = (error) => {
                    Helper.showErrorMessage(
                        this.widgetRef.notificationsService,
                        error,
                        `Error while ${actionPerform} accounts`
                    );
                };
                new HitApi(
                    apiArgs,
                    this.widgetRef.httpService,
                    this.widgetRef.ngZone
                ).hitApi();
            },
        };

        this.widgetRef.modalService.openConfirmationModal(modalData);
    }

    openOnboardingModal(formData?: any) {
        const modalArgs: IModalData = {
            modalName: 'Onboard AZURE EA',
            sourceId: this.widgetRef.uniqueIdentity,
            extraStepContainerClass: 'remove-form-space',
            modalType: ModalType.MIDDLE,
            modalIcon: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-chalkboard-teacher'
            },
            modalSteps: [
                {
                    stepName: 'AZURE EA ONBOARDING',
                    stepData: {
                        componentToLoad: EaOnboardingModalComponent,
                        payload: {
                            data: {
                                create: this.widgetRef.widgetData.widgetInfo
                                    .create,
                                formData: formData
                            }
                        }
                    }
                }
            ],
            modalWidthVw: 65,
            modalAutoHeight: true
        };
        this.widgetRef.modalService.openModal(modalArgs);
    }
}

enum EaAttributeKeys {
    EA_ACCOUNT_ATTRIBUTE = 'AzureEaAccountsInfo',
    ACCOUNT_NAME = 'accountName',
    ACCOUNT_NUMBER = 'accountNumber',
    SPOC = 'spoc',
    SUBSCRIPTION_ID = 'subscriptionID',
    PARTNER_ID = 'partnerId',
}
